import * as THREE from 'three';
import Gl from './index'
import GlObject from './GlObject'
import vertex from './glsl/vertex-01.glsl'
import fragment from './glsl/fragment-01.glsl'
import gsap from 'gsap';

const glsl = require('glslify')
const planeGeometry = new THREE.PlaneBufferGeometry(1, 1, 32, 32);
const planeMaterial = new THREE.ShaderMaterial({
  vertexShader: glsl(vertex),
  fragmentShader: glsl(fragment),
});

const loader = new THREE.TextureLoader();

console.log(glsl)

export default class extends GlObject {
  init(el, index, isTouchDevice = false) {
    super.init(el);

    this.geometry = planeGeometry;
		this.material = planeMaterial.clone();
		
		console.log(this.material)
    
    this.material.uniforms = {
      uTexture: { value: 0 },
      uTime: { value: 0 },
      uProg: { value: 0 },
      uIndex: { value: index },
		}

		const materialUni = this.material.uniforms;
		const planeImg = this.el.querySelector('img');

		this.texture = loader.load(planeImg.src, function(texture) {
      texture.minFilter = THREE.LinearFilter;
			texture.generateMipmaps = false;

			materialUni.uTexture.value = texture;
		},
			undefined,
		function ( err ) {
			console.error( err );
		})

		this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.add(this.mesh);
    
    Gl.scene.add(this);
    this.addEvents(isTouchDevice);
  }

  updateTime(time) {
    this.material.uniforms.uTime.value = time;
  }

  addEvents(isTouchDevice) {
    this.mouseEnter();
		this.mouseLeave();

		if (isTouchDevice) {
			this.windowScroll();
		}
  }

	mouseEnter() {
		this.el.addEventListener('mouseenter', () => {
      gsap.to(this.material.uniforms.uProg, {
        value: 1,
        ease: 'power.inOut',
      });
    });
  }

  mouseLeave() {
    this.el.addEventListener('mouseleave', () => {
      gsap.to(this.material.uniforms.uProg, {
        value: 0,
        ease: 'power.inOut',
      });
    });
	}
	
	windowScroll() {
		let userScrolled = false;
		let materials = this.material.uniforms.uProg;

		if (typeof window !== 'undefined') {
			window.addEventListener('scroll', function (e) {
				userScrolled = true;

				if (userScrolled) {
					gsap.to(materials, {
						value: 1,
						ease: 'power.inOut',
					});
				}

				setTimeout(() => {
					userScrolled = false;
				
					gsap.to(materials, {
						value: 0,
						ease: 'power.inOut',
					});
				}, 1000)
			})
		}
	}
}
