import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { breakpoint, ease } from '../../shared/theme'
import SectionTransition from '../../elements/activeSectionDefault'
import * as THREE from "three";
import Gl from './gl';
import Plane from './gl/Plane';
import PostNewsCard from '../postNewsCard'

const PostWrapper = styled(SectionTransition)`
	padding-bottom: 48px;
	transition: all 200ms ${ease};

	@media ${breakpoint('tablet')} {
		padding-bottom: 280px;
	}
`

const PostGrid = styled.div`
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;

	@media ${breakpoint('tablet')} {
		row-gap: 115px;
		display: grid;
		grid-template-columns: repeat(28, minmax(0, 1fr));
		column-gap: 20px;
	}
`

const MorePosts = ({ isActive, posts, activeCategory }) => {
	const [loading, toggleLoading] = useState(false)
	const [activeLayout, setActiveLayout] = useState(null)
	const [previousLayout, setPreviousLayout] = useState(null)
	const [isTouchDevice, setTouchDevice] = useState(checkTouchDevice())

	if (!posts || posts.length <= 0) {
		return null
	}

	function checkTouchDevice() {
		if (typeof window !== 'undefined') {
			return (
				('ontouchstart' in window) ||
				(navigator.maxTouchPoints > 0) ||
				(navigator.msMaxTouchPoints > 0));
		}
	}

	function cleanPosts(posts) {
		const featuredPost = posts.filter((post) => {
			let {
				postHeader: { featuredPost },
			} = post
			if (featuredPost) {
				return post
			}
		})

		const cleanArray = posts.filter((post) => {
			let {
				postHeader: { featuredPost },
			} = post
			if (!featuredPost || featuredPost == null) {
				return post
			}
		})

		if (featuredPost.length > 0) {
			if (!activeCategory) {
				cleanArray.length < 2
					? cleanArray.push(...featuredPost)
					: cleanArray.splice(2, 0, ...featuredPost)
			} else if (activeCategory.layout != 'alt') {
				cleanArray.length < 2
					? cleanArray.push(...featuredPost)
					: cleanArray.splice(2, 0, ...featuredPost)
			} else {
				cleanArray.length < 3
					? cleanArray.push(...featuredPost)
					: cleanArray.splice(3, 0, ...featuredPost)
			}
		}

		return cleanArray
	}

	const activePosts = cleanPosts(posts)

	useEffect(() => {
		toggleLoading(true)
		if (activeCategory === null) {
			setActiveLayout('default')
		} else {
			if (previousLayout != activeCategory.layout) {
				setActiveLayout(activeCategory.layout)
				setPreviousLayout(activeCategory.layout)
			}
		}

		setTimeout(() => {
			toggleLoading(false)
		}, 200)
	}, [activeCategory])

	useEffect(() => {
		const canvas = document.querySelector('.dom-gl');

		if (canvas != null)
		{
			canvas.classList.add('hidden');

			setTimeout(() => {
				while (Gl.scene.children.length > 0)
				{
					Gl.scene.remove(Gl.scene.children[0])
				}
			}, 200 )

			setTimeout(() => {
				const elements = document.querySelectorAll('.js-plane');
				var planesCreated = 0;
				elements.forEach((el, index) => {
					planesCreated++
					new Plane().init(el, index, isTouchDevice)

					if (planesCreated == elements.length)
					{
						Gl.resize();
						canvas.classList.remove('hidden');
					}
				});

				console.log(Gl.scene.children)
			}, 800 )
		}
	}, [activeCategory])

	return (
		<PostWrapper isActive={isActive} isLoading={loading}
			data-scroll>
			<PostGrid id="post-grid" data-scroll-content>
				{activePosts &&
					activePosts.map((post, index) => (
						<PostNewsCard
							key={post.postId}
							post={post}
							key={index}
							index={index}
							layout={activeLayout == null ? 'default' : activeLayout}
							isTouch={isTouchDevice}
							isLoading={loading}
							isActive={isActive}
							activeCategory={activeCategory}
						/>
					))}
			</PostGrid>
		</PostWrapper>
	)
}

export default MorePosts
