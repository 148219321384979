import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { ease } from '../../shared/theme'

const CardTransition = styled.div`
	opacity: 0;
	transition: all 200ms ${ease};
	transform: translateY(20px);

	${(props) =>
		props.isActive &&
		!props.isLoading &&
		css`
			opacity: 1;
			transform: none;
		`}
`
export default CardTransition

CardTransition.propTypes = {
	isActive: PropTypes.bool,
	isLoading: PropTypes.bool,
}

CardTransition.defaultProps = {
	isActive: false,
	isLoading: false,
}
