import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import { breakpoint, getColor, ease } from '../../shared/theme'
import { InviewElement } from '../../elements/inviewElement'
import CardTransition from '../../elements/activeCardDefault'
import H3 from '../../elements/typography/h3'
import WpLink from '../../elements/wpLink'
import Hero from '../../images/01_transition_szep.png'

const ImageWrap = styled.div`
	position: relative;
	display: block;
	width: 100%;
	overflow: hidden;
	margin-bottom: 12px;

	&::before {
		content: '';
		height: 0;
		position: relative;
		display: block;
		width: 100%;
		padding-top: ${() => {
			return `${(520 / 300) * 100}%`
		}};
	}
`

const CardInner = styled.div`
	display: flex;

	a {
		width: 100%;
	}
`

const Title = styled(H3)`
	font-weight: 700;
	letter-spacing: -0.5px;
	transition: all 200ms ${ease};

	@media (max-width: 539px) {
		font-size: 16px;
	}
`

const Content = styled.div``

const PostCard = styled.div`
	margin-bottom: 60px;
	opacity: ${(props) => (props.isLoading ? 0.6 : 1)};
	transition: opacity 200ms ${ease};

	&:hover {
		color: ${getColor('orange')};
	}

	@media ${breakpoint('mobile')} {
		margin-bottom: 92px;
	}

	@media (max-width: 539px) {
		.parallax-inner {
			transform: none !important;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	&:nth-child(odd) {
		margin-left: auto;

		& ${Content} {
			padding-right: 20px;
		}
	}

	&:nth-child(even) {
		margin-right: auto;

		& ${Content} {
			padding-left: 20px;
		}
	}

	@media ${breakpoint('mobile')} {
		&:nth-child(odd) ${Content} {
			padding-right: 32px;
		}

		&:nth-child(even) ${Content} {
			padding-left: 32px;
		}
	}

	&:nth-child(3n + 1) {
		width: ${() => {
			return `${(300 / 375) * 100}%`
		}};

		${ImageWrap}::before {
			padding-top: ${() => {
				return `${(425 / 300) * 100}%`
			}};
		}
	}

	&:nth-child(3n + 2) {
		width: ${() => {
			return `${(270 / 375) * 100}%`
		}};

		${ImageWrap}::before {
			padding-top: ${() => {
				return `${(300 / 270) * 100}%`
			}};
		}
	}

	&:nth-child(3n + 3) {
		width: ${() => {
			return `${(300 / 375) * 100}%`
		}};

		${ImageWrap}::before {
			padding-top: ${() => {
				return `${(340 / 300) * 100}%`
			}};
		}
	}

	&:nth-child(3n + 4) {
		width: ${() => {
			return `${(290 / 375) * 100}%`
		}};

		${ImageWrap}::before {
			padding-top: ${() => {
				return `${(290 / 300) * 100}%`
			}};
		}
	}

	&:nth-child(3n + 5) {
		width: ${() => {
			return `${(330 / 375) * 100}%`
		}};

		${ImageWrap}::before {
			padding-top: ${() => {
				return `${(480 / 330) * 100}%`
			}};
		}
	}

	&:nth-child(3n + 6) {
		width: ${() => {
			return `${(330 / 375) * 100}%`
		}};

		${ImageWrap}::before {
			padding-top: 100%;
		}
	}

	@media ${breakpoint('tablet')} {
		margin-bottom: 0px;

		&:nth-child(odd) {
			margin-left: 0;
			margin-right: 0;
		}

		&:nth-child(even) {
			margin-left: 0;
			margin-right: 0;
		}

		&:nth-child(odd) ${Content} {
			padding-right: 0;
		}

		&:nth-child(even) ${Content} {
			padding-left: 0;
		}

		&:nth-child(3n + 1) {
			width: auto;
			grid-column: ${(props) =>
				props.layout == 'default' ? '2 / span 7' : '2 / span 8'};

			${ImageWrap}::before {
				padding-top: ${(props) =>
					props.layout == 'default'
						? () => {
								return `${(520 / 300) * 100}%`
						  }
						: () => {
								return `${(500 / 350) * 100}%`
						  }};
			}
		}

		&:nth-child(3n + 2) {
			width: 100%;
			grid-column: ${(props) =>
				props.layout == 'default' ? '10 / span 7' : '13 / span 8'};
			padding-top: ${(props) => (props.layout == 'default' ? '0' : '142px')};

			${ImageWrap}::before {
				padding-top: ${(props) =>
					props.layout == 'default'
						? () => {
								return `${(520 / 300) * 100}%`
						  }
						: () => {
								return `${(530 / 350) * 100}%`
						  }};
			}
		}

		&:nth-child(3n + 3) {
			width: 100%;
			grid-column: ${(props) =>
				props.layout == 'default' ? '19 / span 7' : '21 / span 7'};
			padding-top: ${(props) => (props.layout == 'default' ? '142px' : '0')};

			@media ${breakpoint('tablet')} {
				grid-column: ${(props) =>
					props.layout == 'default' ? '19 / span 8' : '21 / span 7'};
			}

			@media ${breakpoint('desktop')} {
				grid-column: ${(props) =>
					props.layout == 'default' ? '19 / span 7' : '21 / span 7'};
			}

			${ImageWrap}::before {
				padding-top: ${(props) =>
					props.layout == 'default'
						? () => {
								return `${(680 / 430) * 100}%`
						  }
						: () => {
								return `${(520 / 300) * 100}%`
						  }};
			}
		}

		&:nth-child(3n + 4) {
			width: 100%;
			grid-column: ${(props) =>
				props.layout == 'default' ? '4 / span 7' : '2 / span 9'};
			padding-top: ${(props) => (props.layout == 'default' ? '95px' : '0')};

			${ImageWrap}::before {
				padding-top: ${(props) =>
					props.layout == 'default'
						? () => {
								return `${(500 / 350) * 100}%`
						  }
						: () => {
								return `${(680 / 430) * 100}%`
						  }};
			}
		}

		&:nth-child(3n + 5) {
			width: 100%;
			grid-column: ${(props) =>
				props.layout == 'default' ? '12 / span 7' : '11 / span 7'};

			${ImageWrap}::before {
				width: 100%;
				padding-top: ${(props) =>
					props.layout == 'default'
						? () => {
								return `${(500 / 350) * 100}%`
						  }
						: () => {
								return `${(520 / 300) * 100}%`
						  }};
			}
		}

		&:nth-child(3n + 6) {
			grid-column: ${(props) =>
				props.layout == 'default' ? '21 / span 7' : '20 / span 8'};
			padding-top: ${(props) => (props.layout == 'default' ? '95px' : '100px')};

			${ImageWrap}::before {
				padding-top: ${(props) =>
					props.layout == 'default'
						? () => {
								return `${(530 / 350) * 100}%`
						  }
						: () => {
								return `${(500 / 350) * 100}%`
						  }};
			}
		}
	}

	@media ${breakpoint('desktop')} {
		&:nth-child(3n + 1) {
			width: auto;
			grid-column: ${(props) =>
				props.layout == 'default' ? '5 / span 5' : '5 / span 6'};

			${ImageWrap}::before {
				padding-top: ${(props) =>
					props.layout == 'default'
						? () => {
								return `${(520 / 300) * 100}%`
						  }
						: () => {
								return `${(500 / 350) * 100}%`
						  }};
			}
		}

		&:nth-child(3n + 2) {
			width: 100%;
			grid-column: ${(props) =>
				props.layout == 'default' ? '11 / span 5' : '15 / span 6'};

			${ImageWrap}::before {
				padding-top: ${(props) =>
					props.layout == 'default'
						? () => {
								return `${(520 / 300) * 100}%`
						  }
						: () => {
								return `${(530 / 350) * 100}%`
						  }};
			}
		}

		&:nth-child(3n + 3) {
			width: 100%;
			grid-column: ${(props) =>
				props.layout == 'default' ? '18 / span 7' : '22 / span 5'};

			${ImageWrap}::before {
				padding-top: ${(props) =>
					props.layout == 'default'
						? () => {
								return `${(680 / 430) * 100}%`
						  }
						: () => {
								return `${(520 / 300) * 100}%`
						  }};
			}
		}

		&:nth-child(3n + 4) {
			width: 100%;
			grid-column: ${(props) =>
				props.layout == 'default' ? '6 / span 5' : '2 / span 7'};

			${ImageWrap}::before {
				padding-top: ${(props) =>
					props.layout == 'default'
						? () => {
								return `${(500 / 350) * 100}%`
						  }
						: () => {
								return `${(680 / 430) * 100}%`
						  }};
			}
		}

		&:nth-child(3n + 5) {
			width: 100%;
			grid-column: ${(props) =>
				props.layout == 'default' ? '12 / span 5' : '10 / span 5'};

			${ImageWrap}::before {
				width: 100%;
				padding-top: ${(props) =>
					props.layout == 'default'
						? () => {
								return `${(500 / 350) * 100}%`
						  }
						: () => {
								return `${(520 / 300) * 100}%`
						  }};
			}
		}

		&:nth-child(3n + 6) {
			grid-column: ${(props) =>
				props.layout == 'default' ? '19 / span 5' : '17 / span 6'};

			${ImageWrap}::before {
				padding-top: ${(props) =>
					props.layout == 'default'
						? () => {
								return `${(530 / 350) * 100}%`
						  }
						: () => {
								return `${(500 / 350) * 100}%`
						  }};
			}
		}
	}

	${(props) =>
		props.featured &&
		css`
			order: -1;
			margin-left: 0 !important;
			margin-right: auto;

			& ${Content} {
				padding-left: 20px;
				padding-right: 0;

				@media ${breakpoint('mobile')} {
					padding-left: 32px;
				}

				@media ${breakpoint('tablet')} {
					padding-right: 0;
				}
			}

			@media ${breakpoint('tablet')} {
				order: initial;
			}

			& ~ div:nth-child(even) {
				margin-right: 0;
				margin-left: auto;

				& ${Content} {
					padding-left: 0;
					padding-right: 20px;

					@media ${breakpoint('mobile')} {
						padding-right: 32px;
					}

					@media ${breakpoint('tablet')} {
						padding-right: 0;
					}
				}
			}

			& ~ div:nth-child(odd) {
				margin-left: 0;
				margin-right: 0;

				& ${Content} {
					padding-right: 0;
					padding-left: 20px;

					@media ${breakpoint('mobile')} {
						padding-left: 32px;
					}

					@media ${breakpoint('tablet')} {
						padding-left: 0;
					}
				}
			}

			${ImageWrap} {
				&::after {
					/* content: ''; */
					display: block;
					width: 100%;
					height: 12px;
					background: ${getColor('orange')};
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 5;
				}
			}

			${Title} {
				color: ${getColor('orange')};

				@media ${breakpoint('tablet')} {
					font-size: 24px;
				}

				@media ${breakpoint('desktop')} {
					font-size: 32px;
				}
			}
		`}
`

const Img = styled.img`
	width: 100%;
	transition: all 200ms ${ease};
	position: absolute;
	top: 0;
	left: 0;
	max-width: 100%;
`

const Category = styled.span`
	font-size: 10px;
	display: block;
	text-transform: uppercase;
	color: ${getColor('orange')};
	margin-bottom: 2px;

	span {
		text-decoration: underline;
		margin-right: 0.5em;
	}

	@media ${breakpoint('tablet')} {
		margin-bottom: 12px;
	}
`

const PostNewsCard = ({
	post,
	index,
	isActive,
	layout,
	isLoading,
	isTouch,
	activeCategory,
}) => {
	const {
		title,
		featuredImage,
		postHeader: { featuredPost },
		categories,
		uri,
	} = post

	const [isHidden, setVisibility] = useState(false)
	const [postCategories, setCategories] = useState(categories)

	useEffect(() => {
		let checkVisible = false

		if (!activeCategory) {
			checkVisible = true
		} else {
			postCategories.nodes.forEach((category) => {
				if (category.slug === activeCategory.slug) {
					checkVisible = true
				}
			})
		}

		setVisibility(checkVisible)
	}, [activeCategory])

	return (
		<>
			{isHidden && (
				<PostCard
					layout={layout}
					featured={featuredPost}
					className={[index]}
					isActive={true}
					isLoading={isLoading}
					isVisible={isHidden}
				>
					<InviewElement>
						<CardInner
							isActive={isActive}
							isLoading={isLoading}
							isTouch={isTouch}
						>
							<WpLink uri={uri} isBlog={true} title={title}>
								<ImageWrap className="item js-plane">
									{featuredImage && (
										<Img
											src={featuredImage.node.sourceUrl}
											alt={title}
											isTouch={isTouch}
											className="bg-cover item__img"
										/>
									)}
								</ImageWrap>
								<Content>
									<Category>
										{categories.nodes.map(function (tag, index) {
											return <span key={index}>{`#${tag.name}`}</span>
										})}
									</Category>
									<Title>
										{title}
										{isTouch}
									</Title>
								</Content>
							</WpLink>
						</CardInner>
					</InviewElement>
				</PostCard>
			)}
		</>
	)
}

export default PostNewsCard

PostNewsCard.propTypes = {
	post: PropTypes.object,
}

PostNewsCard.defaultProps = {
	post: {},
}
