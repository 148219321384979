import React, { useState, createContext } from 'react'
import InviewWrapper from '../../elements/inviewWrapper'
import PageHeaderImage from '../../components/pageHeaderImage'
import PageIntroText from '../../components/pageHeaderIntro'
import PostFilters from '../../components/blogFilters'
import MorePosts from '../../components/morePosts'
import SEO from '../../components/SEO'

export const BlogCategoryContext = createContext(null)

const Blog = ({ pageContext }) => {
	const [activeCategory, setActiveCategory] = useState(null)
	const { nodes, categoryNodes, pageHeader } = pageContext

	if (nodes == undefined || !nodes) {
		return null
	}

	const postCategories = categoryNodes.map((category, index) => {
		category.layout = index % 2 == 0 ? 'default' : 'alt'
		return category
	})

	return (
		<>
			<SEO title="More" description="Blog posts" keywords={[`blog`]} />
			<PageHeaderImage content={pageHeader} />

			<BlogCategoryContext.Provider value={activeCategory}>
				<InviewWrapper>
					<PageIntroText content={pageHeader} />
					<PostFilters
						filters={postCategories}
						activeCategory={(activeFilter) => setActiveCategory(activeFilter)}
					/>
					<MorePosts posts={nodes} activeCategory={activeCategory} />
				</InviewWrapper>
			</BlogCategoryContext.Provider>
		</>
	)
}

export default Blog
