import React, { useState } from 'react'
import styled from 'styled-components'
import InnerWrapper from '../../elements/innerWrapper'
import PropTypes from 'prop-types'
import SectionTransition from '../../elements/activeSectionDefault'
import { breakpoint, getColor } from '../../shared/theme'

const FilterWrap = styled.div`
	padding: 48px 0;
	background: ${getColor('greyLight')};
	margin-bottom: 48px;
	overflow: auto;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media ${breakpoint('tablet')} {
		padding: 64px 0;
		margin-bottom: ${() => {
			return `${180 - 64}px`
		}};
	}
`

const FilterInner = styled(SectionTransition)`
	display: flex;
`

const FilterButton = styled.button`
	font-size: 8px;
	text-transform: uppercase;
	font-weight: 700;
	text-decoration: underline;
	transition: all 200ms ${(props) => props.theme.ease};
	margin-right: 48px;
	flex-shrink: 0;
	color: ${(props) =>
		props.currentCategory ? getColor('orange') : getColor('black')};

	&:last-child {
		margin-right: 0;
		padding-right: 20px;
	}

	&.active,
	&:hover {
		color: ${getColor('orange')};
	}

	@media ${breakpoint('tablet')} {
		font-size: 14px;
		margin-right: 82px;

		&:last-child {
			padding-right: 32px;
		}

		&:first-child {
			padding-left: ${() => {
				return `${(282 / 1920) * 100}%`
			}};
		}
	}

	@media ${breakpoint('desktop')} {
		margin-right: 110px;
	}
`

const PostFilters = ({ isActive, filters, activeCategory }) => {
	const [currentCategory, setCurrentCategory] = useState(null)
	if (!filters || filters.length <= 0) {
		return null
	}
	return (
		<FilterWrap>
			<InnerWrapper>
				<FilterInner isActive={isActive}>
					<FilterButton
						onClick={() => {
							activeCategory(null)
							setCurrentCategory(null)
						}}
						slug="all"
						layout="default"
						currentCategory={currentCategory === null}
					>
						All
					</FilterButton>
					{filters.map((filter, index) => (
						<FilterButton
							key={index}
							onClick={() => {
								activeCategory(filter)
								setCurrentCategory(filter)
							}}
							slug={filter.slug}
							currentCategory={
								currentCategory == null
									? false
									: currentCategory.slug === filter.slug
							}
						>
							{filter.name}
						</FilterButton>
					))}
				</FilterInner>
			</InnerWrapper>
		</FilterWrap>
	)
}

PostFilters.propTypes = {
	fiters: PropTypes.array,
}

PostFilters.defaultProps = {
	fiters: [],
}

export default PostFilters
